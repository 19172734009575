<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.timeBegin"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.timeEnd"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

  </div>
</template>

<script>
import { now_month_time, nowDateTime } from '@/utils/date.js'
export default {
  data () {
    return {
      tableData: [],
      form: {},
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'statics_testpaperStatics_teacherInfo_admin',
      tableColumns: [
        { prop: 'edu_school_name', align: 'center', label: '学校名', width: '', showToopic: false },
        { prop: 'edu_user_id', align: 'center', label: '教师ID', width: '100', showToopic: true },
        { prop: 'edu_user_name', align: 'center', label: '教师姓名', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'edu_paper_id', align: 'center', label: '教师试卷ID', width: '', showToopic: false },
        { prop: 'paper_score', align: 'center', label: '试卷分数', width: '', showToopic: false },
        { prop: 'paper_description', align: 'center', label: '试卷描述', width: '', showToopic: false },
        { prop: 'create_time', align: 'center', label: '提交时间', width: '', showToopic: false },
        { prop: 'user_paper_count', align: 'center', label: '学生做卷次数', width: '', showToopic: false },
        { prop: 'user_score_rate', align: 'center', label: '学生得分率', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '100', type: 'img', btns: [
            { content: '试卷详细', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  created () {
  },
  mounted () {
    this.form = JSON.parse(this.$route.query.form)
    this.search()
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      if ('{}' == JSON.stringify(this.form)) {
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/cp_detail',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      this.$router.push(`/testpaper_statics/paperinfo?edu_paper_id=${row.edu_paper_id}`)
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>